<template>
	<div>
		<a-spin :spinning="loading">
			<a-form ref="form" :model="formState" name="form" @finish="onSearch">
				<!-- 搜索栏 -->
				<a-row>
					<a-form-item label="一卡通名称" name="title" class="ui-form__item">
						<a-input v-model:value="formState.title" placeholder="请输入一卡通名称"></a-input>
					</a-form-item>

					<!-- <a-form-item label="一卡通卡号" name="cardNo" class="ui-form__item">
						<a-input v-model:value="formState.cardNo" placeholder="请输入一卡通卡号"></a-input>
					</a-form-item> -->

					<a-form-item label="用户信息" name="userInfo" class="ui-form__item">
						<a-input v-model:value="formState.userInfo" placeholder="请输入购买用户信息"></a-input>
					</a-form-item>

					<a-form-item label="交易流水号" name="outTradeNo" class="ui-form__item">
						<a-input v-model:value="formState.outTradeNo" placeholder="请输入交易流水号"></a-input>
					</a-form-item>

					<a-form-item label="订单号" name="orderNo" class="ui-form__item">
						<a-input v-model:value="formState.orderNo" placeholder="请输入订单号"></a-input>
					</a-form-item>

					<!-- <a-form-item label="一卡通状态" name="cardStatus" class="ui-form__item">
						<a-select v-model:value="formState.cardStatus" placeholder="请选择一卡通状态" allow-clear
							style="width: 195px;">
							<a-select-option :value="1">已激活</a-select-option>
							<a-select-option :value="2">转赠中</a-select-option>
							<a-select-option :value="3">已赠送</a-select-option>
							<a-select-option :value="0">待激活</a-select-option>
						</a-select>
					</a-form-item> -->

					<a-form-item label="卡类型" name="cardType" class="ui-form__item">
						<a-select v-model:value="formState.cardType" placeholder="请选择卡类型" allow-clear
							style="width: 195px;">
							<a-select-option :value="1">普通卡</a-select-option>
							<a-select-option :value="2">至尊卡</a-select-option>
							<a-select-option :value="3">礼品卡</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item label="交易时间" name="payTime" class="ui-form__item">
						<a-range-picker v-model:value="formState.payTime"></a-range-picker>
					</a-form-item>
				</a-row>

				<a-row>
					<a-col :span="18">
						<exportReport type="userUnionCardOrderStrategy" :searchData="searchData"></exportReport>
					</a-col>
					<a-col :span="6" style="text-align: right">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>

			<div style="margin-top: 20px;">
				<a-table :pagination="pagination" :columns="columns" :dataSource="list" rowKey="id"
					:scroll="{ x: 2000 }">
					<template #bodyCell="{ column, record }">
						<template v-if="column.key === 'cardType'">
							<div v-if="record.cardType === 1">普通卡</div>
							<div v-if="record.cardType === 2">主题卡</div>
							<div v-if="record.cardType === 3">礼品卡</div>
						</template>
						<template v-if="column.key === 'cardStatus'">
							<div v-if="record.cardStatus === 0">待激活</div>
							<div v-if="record.cardStatus === 1">已激活</div>
							<div v-if="record.cardStatus === 2">转赠中</div>
							<div v-if="record.cardStatus === 3">已赠送</div>
						</template>
						<template v-if="column.key === 'userInfo'">
							<div>
								<div>用户昵称：{{ record.nickname || '-' }}</div>
								<div>用户手机号：{{ record.phone || '-' }}</div>
							</div>
						</template>
						<template v-if="column.key === 'parPrice'">
							{{ record.unionCard.parPrice }}
						</template>
						<template v-if="column.key === 'payTime'">
							{{ transDateTime(record.payTime) }}
						</template>
						<template v-if="column.key === 'fees'">
							{{ (record.payment * 0.0054).toFixed(3) }}
						</template>
						<template v-if="column.key === 'price'">
							{{ (record.payment - (record.payment * 0.0054)).toFixed(3) }}
						</template>
						<template v-if="column.key === 'action'">
							<a-button type="link">售后</a-button><!--  只有未激活的才能售后  -->
						</template>
					</template>
				</a-table>
			</div>
		</a-spin>
	</div>
</template>

<script>
	import exportReport from '@/components/exportReport/exportReport.vue';
	import {
		getUnionCardOrderList
	} from "@/service/modules/report";

	export default {
		components: {
			exportReport
		},
		data() {
			return {
				showModal: false,
				isEdit: false,
				isSee: false,
				id: 0,
				loading: false,
				searchData: {},
				modelRef: {},
				formState: {},
				columns: [{
					title: '用户信息', //（用户昵称、所属用户id、用户手机号）
					key: 'userInfo',
				}, {
					title: '一卡通名称',
					dataIndex: 'title',
				}, {
					title: '卡类型',
					key: 'cardType',
					width: 120
				}, {
					title: '订单号',
					dataIndex: 'orderNo',
					width: 190
				}, {
					title: '交易流水号',
					dataIndex: 'outTradeNo',
				}, {
					title: '初始金额',
					key: 'parPrice',
					width: 130
				}, {
					title: '实付金额',
					dataIndex: 'payment',
					width: 100
				}, {
					title: '微信手续费(按0.54%计算)',
					key: 'fees'
				}, {
					title: '微信预计到账',
					key: 'price'
				}, {
					title: '交易时间',
					key: 'payTime',
				}, {
					title: '操作',
					key: 'action',
					width: 100,
					fixed: 'right'
				}],
				list: [],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					pageSizeOptions: ['10', '20', '50', '100', '500'],
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
			}
		},
		created() {
			this.onSearch();
		},
		methods: {
			reset() {
				this.$refs.form.resetFields();
				this.onSearch();
			},
			onSearch() {
				this.pagination.current = 1;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				this.getData();
			},
			async getData() {
				if (this.formState.payTime && this.formState.payTime.length === 2) {
					this.searchData.payStartTime = this.moment(this.formState.payTime[0].startOf('day')).unix();
					this.searchData.payEndTime = this.moment(this.formState.payTime[1].endOf('day')).unix();
				}
				this.loading = true;
				try {
					let ret = await getUnionCardOrderList({
						page: this.pagination.current,
						pageSize: this.pagination.pageSize,
						...this.searchData
					})
					this.loading = false;
					if (ret.code === 200) {
						this.list = ret.data.list;
						this.pagination.total = ret.data.totalCount;
					}
				} catch (e) {
					this.loading = false;
				}
			},
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}
</style>